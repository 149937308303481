import React from 'react';
import { useNavigate } from 'react-router-dom';
import dehImage from '../images/logdeh.png';

const Header = ({ onCategoryClick, selectedCategory }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    // Navigate to home with Digital view as default
    navigate('/', { 
      state: { 
        fromItemPage: true,
        selectedCategory: 'digital'
      } 
    });
  };

  // Simplified navigation bar with just the logo
  return (
    <nav className="fixed top-0 w-full bg-white shadow-sm p-4 z-50">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <button 
          onClick={handleLogoClick}
          className="flex items-center space-x-3"
        >
          <img 
            src={dehImage} 
            alt="DEH" 
            className="w-8 h-8"
          />
          <span className="text-xs text-gray-500">
            Daniel Eigler-Harding
          </span>
        </button>
      </div>
    </nav>
  );
};

export default Header;