import React, { useState } from 'react';
import MediaContent from './MediaContent';

const MediaCarousel = ({ media }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % media.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + media.length) % media.length);
  };

  const ChevronLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
    </svg>
  );

  const ChevronRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
    </svg>
  );

  const isVideo = media[currentIndex].type === 'video';

  return (
    <div 
      className="relative w-full max-w-4xl mx-auto mb-8"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Remove rounded corners and overflow for videos */}
      <div className={isVideo ? '' : 'rounded-lg bg-gray-100 overflow-hidden'}>
        {/* Remove relative positioning for videos */}
        <div className={isVideo ? '' : 'relative'}>
          <MediaContent content={media[currentIndex]} />
          
          {/* Navigation buttons */}
          {media.length > 1 && (
            <>
              <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-colors z-20"
                aria-label="Previous"
              >
                <ChevronLeft />
              </button>
              <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-colors z-20"
                aria-label="Next"
              >
                <ChevronRight />
              </button>
            </>
          )}

          {/* Thumbnails - moved above video for video content */}
          {isHovering && media.length > 1 && (
            <div className={`${isVideo ? 'relative' : 'absolute'} bottom-0 inset-x-0 bg-black/50 flex justify-center items-center p-4 space-x-2 z-20`}>
              {media.map((item, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`flex-shrink-0 w-12 h-12 rounded-lg overflow-hidden transition-transform hover:scale-110 ${
                    currentIndex === index ? 'ring-2 ring-white' : ''
                  }`}
                >
                  {item.type === 'website' ? (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-sm">🌐</span>
                    </div>
                  ) : item.type === 'video' ? (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-sm">▶</span>
                    </div>
                  ) : (
                    <img
                      src={item.url}
                      alt={item.description}
                      className="w-full h-full object-cover"
                    />
                  )}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Caption */}
        <div className="p-3 bg-white text-center">
          <p className="text-sm text-gray-600">{media[currentIndex].caption}</p>
        </div>
      </div>
    </div>
  );
};

export default MediaCarousel;