import React, { useEffect, useRef } from 'react';

const MyceliumBackground = () => {
  const svgRef = useRef(null);
  const animationFrameRef = useRef(null);
  const lastBranchTimeRef = useRef(0);
  const activePathsRef = useRef(0);
  const MAX_PATHS = 85;
  
  useEffect(() => {
    const createBranch = () => {
      const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
      const startX = Math.random() * window.innerWidth;
      const startY = Math.random() * window.innerHeight;
      
      let d = `M ${startX} ${startY}`;
      let x = startX;
      let y = startY;
      let prevAngle = Math.random() * Math.PI * 2;
      
      const segments = 6 + Math.floor(Math.random() * 4);
      
      for (let i = 0; i < segments; i++) {
        const angleVariation = (Math.random() - 0.5) * Math.PI * 0.8;
        const angle = prevAngle + angleVariation;
        prevAngle = angle;
        
        const length = 20 + Math.random() * 40;
        const endX = x + Math.cos(angle) * length;
        const endY = y + Math.sin(angle) * length;
        
        const ctrlDistance = length * (0.5 + Math.random() * 0.5);
        const ctrl1Angle = angle + (Math.random() - 0.5) * Math.PI * 0.5;
        const ctrl2Angle = angle + (Math.random() - 0.5) * Math.PI * 0.5;
        
        const ctrl1X = x + Math.cos(ctrl1Angle) * ctrlDistance;
        const ctrl1Y = y + Math.sin(ctrl1Angle) * ctrlDistance;
        const ctrl2X = endX - Math.cos(ctrl2Angle) * ctrlDistance;
        const ctrl2Y = endY - Math.sin(ctrl2Angle) * ctrlDistance;
        
        d += ` C ${ctrl1X} ${ctrl1Y}, ${ctrl2X} ${ctrl2Y}, ${endX} ${endY}`;
        
        if (Math.random() > 0.4) {
          const subBranches = 1 + Math.floor(Math.random() * 2);
          
          for (let j = 0; j < subBranches; j++) {
            const subAngle = angle + (Math.random() - 0.5) * Math.PI * 1.2;
            const subLength = length * (0.3 + Math.random() * 0.4);
            const subEndX = x + Math.cos(subAngle) * subLength;
            const subEndY = y + Math.sin(subAngle) * subLength;
            
            const subCtrlDist = subLength * (0.6 + Math.random() * 0.6);
            const subCtrl1Angle = subAngle + (Math.random() - 0.5) * Math.PI * 0.6;
            const subCtrl2Angle = subAngle + (Math.random() - 0.5) * Math.PI * 0.6;
            
            const subCtrl1X = x + Math.cos(subCtrl1Angle) * subCtrlDist;
            const subCtrl1Y = y + Math.sin(subCtrl1Angle) * subCtrlDist;
            const subCtrl2X = subEndX - Math.cos(subCtrl2Angle) * subCtrlDist;
            const subCtrl2Y = subEndY - Math.sin(subCtrl2Angle) * subCtrlDist;
            
            d += ` M ${x} ${y} C ${subCtrl1X} ${subCtrl1Y}, ${subCtrl2X} ${subCtrl2Y}, ${subEndX} ${subEndY}`;
            d += ` M ${endX} ${endY}`;
          }
        }
        
        x = endX;
        y = endY;
      }
      
      const strokeWidth = 0.5 + Math.random() * 1;
      
      path.setAttribute("d", d);
      path.setAttribute("stroke", "rgb(209, 213, 219)");
      path.setAttribute("stroke-width", strokeWidth.toString());
      path.setAttribute("fill", "none");
      path.setAttribute("opacity", "0");
      
      const duration = 2.5 + Math.random() * 2;
      path.style.animation = `growPath ${duration}s forwards`;
      
      return path;
    };

    const addBranch = () => {
      const svg = svgRef.current;
      if (svg && activePathsRef.current < MAX_PATHS) {
        const branch = createBranch();
        svg.appendChild(branch);
        activePathsRef.current++;
        
        const lifetime = 6000 + Math.random() * 4000;
        setTimeout(() => {
          if (branch.parentNode === svg) {
            svg.removeChild(branch);
            activePathsRef.current--;
          }
        }, lifetime);
      }
    };

    const animate = (timestamp) => {
      if (timestamp - lastBranchTimeRef.current >= 150) { // Minimum delay between branches
        addBranch();
        lastBranchTimeRef.current = timestamp;
      }
      
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    // Initial branches
    const initialBranches = Math.min(8, MAX_PATHS);
    for (let i = 0; i < initialBranches; i++) {
      addBranch();
    }

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden z-0">
      <svg
        ref={svgRef}
        className="w-full h-full absolute inset-0"
      />
      <style>
        {`
          @keyframes growPath {
            0% {
              opacity: 0;
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
            }
            15% {
              opacity: 0.8;
            }
            85% {
              opacity: 0.8;
            }
            100% {
              opacity: 0;
              stroke-dasharray: 1000;
              stroke-dashoffset: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default MyceliumBackground;