import React, { useState, useEffect } from 'react';

const MediaContent = ({ content }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Handle website previews
  if (content.type === 'website') {
    // Mobile version: Show a clickable preview card
    if (isMobile) {
      return (
        <a 
          href={content.url}
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="p-6 text-center">
            <div className="mb-4">
              <svg className="w-12 h-12 mx-auto text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">View Website</h3>
            <p className="text-sm text-gray-500">Tap to open in new tab</p>
          </div>
        </a>
      );
    }

    // Desktop version: Show iframe preview
    return (
      <a 
        href={content.url}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full h-[600px] overflow-hidden relative bg-white hover:opacity-95 transition-opacity"
      >
        <iframe
          src={content.url}
          title={content.alt || 'Website preview'}
          className="absolute top-0 left-0 w-[1600px] h-[1000px]"
          style={{
            transform: 'scale(0.6)',
            transformOrigin: '0 0',
            border: 'none',
          }}
          loading="lazy"
        />
      </a>
    );
  }

  // Handle videos
  if (content.type === 'video') {
    return (
      <video
        src={content.url}
        controls
        className="w-full max-h-[70vh]"
        controlsList="nodownload"
        playsInline
      />
    );
  }

  // Handle images
  return (
    <div className={`w-full ${isMobile ? 'h-[300px]' : 'h-[600px]'} bg-gray-100 flex items-center justify-center`}>
      <img 
        src={content.url} 
        alt={content.alt || 'Content preview'}
        className="max-w-full max-h-full object-contain"
      />
    </div>
  );
};

export default MediaContent;