import React from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import projectData from '../data/projectData';
import MyceliumBackground from '../components/MyceliumBackground'
import MediaCarousel from '../components/MediaCarousel';

const ItemPage = () => {
    const { category, itemId } = useParams();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(category);
  
    const handleCategoryClick = (newCategory) => {
      setSelectedCategory(newCategory);
      navigate('/', { state: { selectedCategory: newCategory } });
    };
  
    const handleBack = () => {
      const item = projectData[itemId.toLowerCase()];
      const isDigitalItem = item?.category === 'Digital';
      
      navigate('/', { 
        state: { 
          fromItemPage: true,
          selectedCategory: isDigitalItem ? 'digital' : category 
        } 
      });
    };
  
    if (!category || !itemId) {
      return (
        <div className="min-h-screen">
          <Header 
            onCategoryClick={handleCategoryClick} 
            selectedCategory={selectedCategory}
          />
          <div className="pt-24 px-4"> {/* Increased padding-top */}
            <div className="max-w-6xl mx-auto py-12">
              <h1 className="text-2xl font-bold mb-4">Page not found</h1>
              <button 
                onClick={() => navigate('/')}
                className="text-blue-600 hover:underline"
              >
                Back to home
              </button>
            </div>
          </div>
        </div>
      );
    }
  
    const formattedItemId = itemId.toLowerCase().replace(/\s+/g, '-');
    const item = projectData[formattedItemId];
  
    if (!item) {
      return (
        <div className="min-h-screen">
          <Header 
            onCategoryClick={handleCategoryClick} 
            selectedCategory={selectedCategory}
          />
          <div className="pt-24 px-4"> {/* Increased padding-top */}
            <div className="max-w-6xl mx-auto py-12">
              <h1 className="text-2xl font-bold mb-4">Item not found</h1>
              <button 
                onClick={() => navigate('/', { state: { selectedCategory: category } })}
                className="text-blue-600 hover:underline"
              >
                Back to home
              </button>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="min-h-screen">
        <MyceliumBackground />
        <Header 
          onCategoryClick={handleCategoryClick} 
          selectedCategory={selectedCategory}
        />
        <div className="pt-24 px-4">
          <div className="max-w-4xl mx-auto py-8">
            {/* Back Navigation */}
            <div className="mb-8">
              <button 
                onClick={handleBack}
                className="text-blue-600 hover:underline"
              >
                ← Back to {category}
              </button>
            </div>
  
            {/* Title Section */}
            <div className="mb-12">
              <h1 className="text-4xl font-bold mb-2">{item.title}</h1>
              <p className="text-gray-500">{item.year}</p>
            </div>
  
            {/* Media Carousel */}
            {item.media && item.media.length > 0 && (
              <MediaCarousel media={item.media} />
            )}
  
            {/* Main content */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
              {/* Left column - main content */}
              <div className="lg:col-span-2 space-y-8">
                <section>
                  <h2 className="text-2xl font-semibold mb-4">Overview</h2>
                  <p className="text-gray-700 leading-relaxed">
                    {item.description}
                  </p>
                </section>
  
                <section>
                  <h2 className="text-2xl font-semibold mb-4">Key Responsibilities</h2>
                  <ul className="space-y-2">
                    {item.details.map((detail, index) => (
                      <li key={index} className="flex items-start">
                        <span className="text-blue-600 mr-2">•</span>
                        <span>{detail}</span>
                      </li>
                    ))}
                  </ul>
                </section>
  
                <section>
                  <h2 className="text-2xl font-semibold mb-4">Impact</h2>
                  <p className="text-gray-700 leading-relaxed">
                    {item.impact}
                  </p>
                </section>
              </div>
  
              {/* Right column - sidebar */}
              <div className="space-y-8">
                <section className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-xl font-semibold mb-4">Technologies</h3>
                  <div className="flex flex-wrap gap-2">
                    {item.technologies.map((tech, index) => (
                      <span 
                        key={index}
                        className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ItemPage;