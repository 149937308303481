import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import ItemPage from './pages/ItemPage';

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';
  const [selectedCategory, setSelectedCategory] = useState('all');

  // Update selectedCategory when URL changes
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1]) {
      // Get category from URL and set it
      const urlCategory = pathParts[1].toLowerCase();
      setSelectedCategory(urlCategory);
    } else {
      // If we're on the home page, get category from location state or default to 'all'
      setSelectedCategory(location.state?.selectedCategory || 'all');
    }
  }, [location]);

  const handleCategoryClick = (category) => {
    const normalizedCategory = category.toLowerCase();
    setSelectedCategory(normalizedCategory);
    navigate('/', { state: { selectedCategory: normalizedCategory } });
  };

  return (
    <div className="relative"> {/* Changed from bg-neutral-50 */}
      {!isHomePage && (
        <Header 
          onCategoryClick={handleCategoryClick} 
          selectedCategory={selectedCategory}
        />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:category/:itemId" element={<ItemPage />} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;